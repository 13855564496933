@import "@/assets/styles/global/lib.scss";

.rz-jobs {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .wrapper {
    position: relative;
  }
  .hero-container {
    background: var(--rz-bg-color);
    padding: size(50) size(20) size(100);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) size(120);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0);
    }
    .content-wrapper {
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(500);
      }
      &.new-version {
        display: inline-block;
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          width: 48%;
          padding-right: size(40);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(500);
          width: 100%;
        }
        h1 {
          padding-top: 0;
        }
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        color: var(--regular-text-inv);
      }
      h1 {
        font-weight: 800;
        font-size: size(35);
        line-height: size(45);
        color: var(--regular-text-inv);
        padding-bottom: size(15);
        padding-top: size(15);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(50);
          line-height: size(70);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(80);
          line-height: size(90);
          padding-top: size(550);
          padding-bottom: size(20);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--regular-text-inv);
      }
    }
    .image-wrapper {
      display: inline-block;
      vertical-align: top;
      padding-top: size(30);
      text-align: center;
      @media screen and (min-width: $breakpoint-md) {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 0;
        text-align: left;
      }
      @media screen and (min-width: $breakpoint-lg) {
        text-align: right;
      }
      &.new-version {
        position: static;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          text-align: right;
          width: 45%;
        }
        img {
          width: 100%;
          max-width: size(540);
        }
      }
      .left-container {
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: inline-block;
          vertical-align: top;
          padding-right: size(40);
          padding-top: size(90);
        }
      }
      .right-container {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
          width: 64%;
        }
        .container-1 {
          padding-bottom: size(20);
          @media screen and (min-width: $breakpoint-lg) {
            padding-bottom: size(40);
          }
        }
        .container-2 {
          .wrapper-1 {
            padding-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              padding-right: size(40);
            }
          }
        }
        .container-1,.container-2 {
          display: block;
        }
        .wrapper-1,.wrapper-2 {
          display: inline-block;
          vertical-align: top;
          .image-item {
            text-align: left;
          }
        }
      }
    }
  }
  .rz-content-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    h3 {
      font-weight: bold;
      color: #9B51E0;
      padding-bottom: size(20);
      font-size: size(24);
      line-height: size(27);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
    p {
      font-weight: bold;
      font-size: size(24);
      line-height: size(34);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
  }
  .life-at-rz {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    &.content-section {
      padding-top: 0;
      padding-bottom: 0;
    }
    .heading-wrapper {
      max-width: size(600);
      text-align: left;
      h3 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(37);
        color: var(--rz-copy-color);
        padding-bottom: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--rz-copy-color);
      }
    }
    .content-wrapper {
      padding-top: size(40);
      text-align: center;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(10);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(60);
      }
      div {
        display: inline-block;
        vertical-align: top;
        padding-top: size(15);
        padding-bottom: size(15);
        border-bottom: size(1) dashed #c4c4c4;
        &:last-child {
          border-bottom: none;
          padding-bottom: none;
        }
        @media screen and (min-width: $breakpoint-md) {
          width: calc((100% / 2) - #{size(30)});
          padding-right: size(35);
          padding-top: size(30);
          &:nth-child(2n) {
            padding-right: 0;
          }
          border: none;
          padding-bottom: none;
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: calc((100% / 4) - #{size(30)});
          padding-right: size(40);
          padding-top: 0;
          &:nth-child(2n) {
            padding-right: size(40);
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      h4 {
        font-weight: bold;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
        text-align: left;
      }
      p {
        font-size: size(18);
        line-height: size(28);
        text-align: left;
      }
    }
  }
  .rz-perks {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    .container {
      position: relative;
      .wrapper {
        text-align: center;
      }
      .mobile-view-design {
        display: block;
        position: absolute;
        bottom: 45%;
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          display: none;
        }
      }
      img {
        width: size(40);
        height: size(42);
        transition: transform 0.5s linear;
        -webkit-animation:spin 12s linear infinite;
        -moz-animation:spin 12s linear infinite;
        animation:spin 12s linear infinite;
        display: inline-block;
        @media screen and (min-width: $breakpoint-lg) {
          width: size(79);
          height: size(72);
        }
        &.bg-img-1 {
          right: 0;
          top: size(40);
          position: absolute;
          @media screen and (min-width: $breakpoint-md) {
            right: size(10);
            top: size(20);
          }
          @media screen and (min-width: $breakpoint-lg) {
            right: size(20);
          }
        }
        &.bg-img-2 {
          left: size(-16);
          bottom: size(16);
          position: absolute;
          @media screen and (min-width: $breakpoint-md) {
            left: size(10);
            bottom: size(16);
          }
          @media screen and (min-width: $breakpoint-lg) {
            left: size(20);
            bottom: size(110);
          }
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
    .heading-wrapper {
      max-width: size(600);
      text-align: left;
      h3 {
        font-weight: 800;
        font-size: size(30);
        line-height: size(37);
        color: var(--rz-copy-color);
        padding-bottom: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-size: size(18);
        line-height: size(28);
        color: var(--rz-copy-color);
      }
    }
    .icons-wrapper {
      margin-top: size(30);
      background: url('../assets/rz-website/path-mobile.svg') no-repeat;
      height: size(130);
      background-size: contain;
      @media screen and (min-width: $breakpoint-md) {
        height: size(175);
        background: url('../assets/rz-website/path.svg') no-repeat;
        background-size: contain;
        max-width: size(728);
        margin: 0 auto;
        margin-top: size(40);
      }
      @media screen and (min-width: $breakpoint-lg) {
        background-repeat: no-repeat;
        height: size(210);
        background-size: cover;
        margin-top: size(60);
        max-width: 100%;
      }
      div {
        display: inline-block;
      }
      .wrapper-2 {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: inline-block;
        }
      }
      img {
        display: inline-block;
        width: size(75);
        height: size(74);
        margin: size(17) size(20);
        transition: transform 3s linear;
        &.perks-img-1, &.perks-img-3, &.perks-img-5 {
          animation: wave 5.5s linear infinite;
          @keyframes wave {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-lg) {
                transform: translateY(#{size(40)});
              }
            }
            100% {
              transform: translateY(0);
            }
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(128);
          height: size(128);
        }
        &.perks-img-1 {
          margin: size(15);
          @media screen and (min-width: $breakpoint-md) {
            margin: size(17);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin: size(17) size(25);
          }
        }
        &.perks-img-2 , &.perks-img-2, &.perks-img-3{
          margin: size(15) size(20);
          @media screen and (min-width: $breakpoint-md) {
            margin: size(20) size(28);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin: size(17) size(30);
          }
        }
        &.perks-img-5 {
          margin-left: size(10);
          @media screen and (min-width: $breakpoint-md) {
            margin-left: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-left: size(42);
          }
        }
        &.perks-img-2, &.perks-img-4, &.perks-img-6 {
          animation: wave-out 5.5s linear infinite;
          @keyframes wave-out {
            0% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-lg) {
                transform: translateY(#{size(40)});
              }
            }
            50% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(#{size(30)});
              @media screen and (min-width: $breakpoint-md) {
                transform: translateY(#{size(40)});
              }
            }
          }
        }
      }
    }
    .content-wrapper {
      padding: size(30) size(15);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(40);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(60);
      }
      div {
        display: inline-block;
        vertical-align: top;
        padding-bottom: size(15);
        border-bottom: size(1) dashed #C4C4C4;
        margin-bottom: size(15);
        &:nth-child(4n) {
          padding-top: size(180);
        }
        &:nth-child(3n) {
          border-bottom: none;
        }
        @media screen and (min-width: $breakpoint-md) {
          width: calc((100% / 2) - #{size(40)});
          padding-right: size(40);
          padding-bottom: size(30);
          border: none;
          margin: none;
          &:nth-child(2n) {
            padding-right: 0;
          }
          &:nth-child(4n) {
            padding-top: 0;
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-right: size(80);
          padding-top: 0;
          padding-bottom: size(56);
        }
      }
      h4 {
        font-weight: bold;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
        text-align: left;
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(10);
          border-bottom: size(1) dashed #C4C4C4;
          margin-bottom: size(10);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: size(20);
          border-bottom: size(1) dashed  #C4C4C4;
          margin-bottom: size(20);
        }
      }
      p {
        font-size: size(18);
        line-height: size(26);
        font-weight: normal;
        text-align: left;
      }
    }
  }
}

