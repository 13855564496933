@import "@/assets/styles/global/lib.scss";

.rz-pre-footer {
  padding: size(50) size(20) 0;
  @media screen and (min-width: $breakpoint-md) {
    padding: size(80) size(20) 0;
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(120) size(0) 0;
  }
  h4 {
    font-weight: 800;
    font-size: size(30);
    line-height: size(42);
    color: var(--rz-copy-color);
    @media screen and (min-width: $breakpoint-lg) {
      font-size: size(60);
      line-height: size(74);
    }
  }
  .container {
    padding-bottom: size(20);
    border-bottom: size(3) solid var(--rz-copy-color);
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(40);
    }
    &:last-child {
      padding-top: size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(40);
      }
    }
  }
  a {
    text-decoration: none;
    font-weight: bold;
    font-size: size(30);
    line-height: size(42);
    color: #006AFF;
    display: inline-block;
    @media screen and (min-width: $breakpoint-lg) {
      font-size: size(60);
      line-height: size(74);
    }
    svg {
      display: inline-block;
      vertical-align: middle;
      width: size(16);
      height: size(16);
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
}
