@import "@/assets/styles/global/lib.scss";

.rz-about-us {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .hero-section {
    background: #8E2E42;
    padding: size(50) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0);
    }
    h1 {
      font-size: size(30);
      line-height: size(42);
      color: var(--regular-text-inv);
      font-weight: 800;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-founders-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(80) size(0) 0;
    }
    .wrapper-container {
      .founder-text {
        height: size(75);
        background-image: url('../../src/assets/rz-website/founders.svg');
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: size(200);
        padding-bottom: size(30);
        @media screen and (min-width: $breakpoint-md) {
          height: size(100);
          background-size: size(500);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(200);
          background-size: size(800);
        }
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        padding-bottom: size(4);
        padding-top: size(10);
      }
      p {
        font-weight: normal;
        font-size: size(15);
        line-height: size(21);
      }
      .content-wrapper {
        display: flex;
        justify-content: space-around;
        max-width: size(450);
        margin: 0 auto;
        .container-1 {
          margin-top: size(60);
        }
      }
    }
  }
  .rz-mission-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0) 0;
    }
    p {
      font-weight: 800;
      font-size: size(30);
      line-height: size(42);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-customers-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
    p {
      font-size: size(35);
      line-height: size(42);
      padding-bottom: size(5);
      font-weight: 800;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
  }
  .rz-content-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
  }
  .rz-press-mentions {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(140) size(0) 0;
    }
    h3 {
      font-weight: bold;
      font-size: size(24);
      line-height: size(27);
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
        padding-bottom: size(40);
        padding-bottom: size(30);
      }
    }
    .news-wrapper {
      text-align: center;
      @media screen and (min-width: $breakpoint-lg) {
        text-align: left;
      }
      div {
        padding: size(30);
        border: size(1) solid #DADADA;
        border-radius: size(10);
        max-width: size(268);
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        text-align: left;
        @media screen and (min-width: $breakpoint-md) {
          margin-right: size(30);
          margin-top: size(30);
          height: size(210);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-right: 0;
          margin-top: 0;
          &:nth-child(2n) {
            margin: 0 size(40);
            height: auto;
          }
        }
        img {
          margin-bottom: size(20);
          text-align: left;
          &.img-0 {
            width: size(118);
            height: size(25);
          }
          &.img-1 {
            width: size(184);
            height: size(20);
          }
          &.img-2 {
            width: size(88);
            height: size(25);
          }
        }
        h4 {
          font-size: size(18);
          line-height: size(24);
          padding-bottom: size(20);
          font-weight: bold;
          text-align: left;
        }
        p {
          font-weight: normal;
          font-size: size(18);
          line-height: size(28);
          color: var(--rz-copy-color);
          padding-bottom: size(30);
          text-align: left;
        }
        a {
          text-decoration: none;
          font-weight: bold;
          font-size: size(15);
          line-height: size(21);
          color: var(--rz-copy-color);
          display: inline-block;
          text-align: left;
          cursor: pointer;
          svg {
            display: inline-block;
            width: size(8);
            height: size(8);
            fill: transparent;
            stroke: var(--rz-copy-color);
            margin-left: size(5);
            transition: transform 0.3s linear;
          }
          &:hover {
            svg {
              transform: translate(#{size(4)});
            }
          }
        }
      }
    }
  }
}
