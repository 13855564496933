@import "@/assets/styles/global/lib.scss";

.blog-page {
  h1 {
    font-weight: bold;
    font-size: size(26);
    line-height: size(34);
    padding-bottom: size(18);
    color: var(--rs-black-color);
    text-align: left;
    font-family: var(--hero-font);
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(40);
      line-height: size(46);
    }
  }
  hr {
    border-color: var(--rs-black-color);
    border-bottom: size(1);
    margin: size(20) 0 0;
  }
  .category {
    font-size: size(16);
  }
  h2 {
    color: var(--rs-black-color);
    font-weight: bold;
    font-size: size(22);
    line-height: size(34);
    padding: size(32) 0 0;
    padding-right: 0;
    text-align: left;
    font-family: var(--hero-font);
    border-bottom: size(1) solid var(--rs-black-color);
    margin-bottom: size(16);
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(30);
      line-height: size(42);
    }
  }
  h3 {
    color: var(--rs-black-color);
    font-weight: bold;
    font-size: size(20);
    line-height: size(22);
    text-align: left;
    padding: size(32) 0 size(16);
    font-family: var(--hero-font);
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(26);
      line-height: size(30);
    }
  }
  .p {
    font-size: size(16);
    line-height: size(24);
    color: var(--rs-black-color);
    padding-bottom: size(12);
    display: block;
    text-align: left;
    &:empty {
      padding-bottom: 0;
    }
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);
      line-height: size(30);
    }
    a {
      text-decoration: underline;
      color: var(--rs-black-color);
    }
    em {
      font-style: italic;
      font-weight: normal;
    }
    strong {
      font-weight: bolder;
      em {
        font-weight: bolder;
      }
    }
  }
  p {
    font-size: size(16);
    line-height: size(24);
    color: var(--rs-black-color);
    padding-bottom: size(12);
    text-align: left;
    margin-bottom: 0;
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);
      line-height: size(30);
    }
    &:empty {
      padding-bottom: 0;
    }
  }
  h4, h5, h6 {
    text-align: left;
  }
  ul {
    text-align: left;
    padding-left: size(20);
    margin: size(12) 0 size(12) size(15);
    @media screen and (min-width: $breakpoint-md) {
      margin-left: size(30);
    }
    &>li {
      list-style-type: disc;
      padding-bottom: size(12);
    }
    li {
      ol {
        padding-left: size(30);
        list-style-type: decimal;
        li {
          padding-bottom: size(6);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  ol {
    text-align: left;
    padding-left: size(20);
    margin: size(12) 0 size(12) size(15);
    @media screen and (min-width: $breakpoint-md) {
      margin-left: size(30);
    }
    &>li {
      list-style-type: decimal;
      padding-bottom: size(12);
    }
    li {
      ul {
        padding-left: size(30);
        list-style-type: disc;
        li {
          padding-bottom: size(6);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  code {
    background-color: rgba(0,0,0,.05);
    border-radius: size(3);
    padding: size(4) size(8);
    text-align: left;
  }
  pre {
    text-align: left;
    &>code {
      background-color: rgba(0,0,0,.05);
      display: block;
      padding: size(12);
      -webkit-text-size-adjust: none;
      overflow-x: auto;
      white-space: pre;
      margin-bottom: size(18);
    }
  }
  blockquote {
    text-align: left;
    background-image: url('../assets/doublequotes1.svg');
    background-repeat: no-repeat;
    background-position: 0% size(10);
    background-size: size(36) size(24);
    max-width: size(575);
    margin: 0 auto;
    .p {
      font-size: size(30);
      line-height: size(40);
      color: var(--rs-lavender-color);
      font-style: italic;
      font-weight: normal;
      padding-left: size(45);
      margin: size(12) 0;
      a {
        text-decoration: none;
        color: var(--rs-lavender-color);
      }
    }
    a {
      text-decoration: none;
      color: var(--rs-lavender-color);
    }
    h1,h2,h3 {
      color: var(--rs-lavender-color);
      font-style: italic;
      font-weight: normal;
      padding-left: size(45);
    }
  }
}
.rs-blog-detail {
  --rs-blog-border-radius: #{size(6)};
  .markdown {
    img {
      display: block;
      margin: size(25) auto;
      width: 100%;
      text-align: center;
      border-radius: var(--rs-blog-border-radius);
    }
    li {
      color: var(--rs-black-color);
      font-size: size(16);
      line-height: size(20);
      max-width: size(600);
      margin: auto;
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(22);
      }
      .signup-btn {
        color: var(--rz-link-color);
        display: inline-block;
        font-weight: 600;
        a {
          color: var(--rz-link-color);
        }
        &:hover {
          @include jump-up-animation;
        }
      }
      .block-btn a {
        font-size: var(--rs-title-level5-font-size);
        line-height: size(16);
        font-weight: bold;
        color: var(--rs-black-color);
        border: size(1) solid var(--rs-black-color);
        padding: size(14) size(24);
        display: inline-block;
        border-radius: size(5);
        padding-right: size(16);
        padding-left: size(16);
        @media screen and (min-width: $breakpoint-md) {
          padding-right: size(24);
          padding-left: size(24);
        }
        &:after {
          content: '';
          border: solid var(--rs-black-color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(2.2);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
        &:hover {
          @include jump-up-animation;
        }
      }
      .block-btn.yellow a {
        background: var(--rs-cta-green-color);
      }
      .block-btn.blue a {
        background: var(--rs-cta-blue-color);
        border: size(1) solid var(--rs-white-color);
        color: var(--rs-white-color);
        &:after {
          border: solid var(--rs-white-color);
          border-width: 0 size(2) size(2) 0;
        }
      }
    }
  }
  .section-2 {
    .markdown li:first-child .p {
      font-weight: 600;
    }
  }
  .section-1 {
    img {
      width: 100%;
      max-width: size(500);
      border-radius: var(--rs-blog-border-radius);
    }
    .image-item {
      padding: size(10) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(0) 0;
      }
    }
  }
  .main-image-wrap {
    img {
      max-width: size(500);
      width: 100%;
      height: auto;
      border-radius: var(--rs-blog-border-radius);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(600);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(870);
      }
    }
  }
}
