@import "@/assets/styles/global/lib.scss";

.banner-wrapper {
  .content-wrapper {
    display: inline-block;
    vertical-align: top;
    @media screen and (min-width: $breakpoint-md) {
      width: 50%;
    }
    @media screen and (min-width: $breakpoint-lg) {
      width: size(440);
    }
  }
  .image-wrapper {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-top: size(30);
    width: 100%;
    height: size(350);
    @media screen and (min-width: $breakpoint-md) {
      padding-left: size(30);
      width: 45%;
      padding-top: size(75);
      height: size(400);
    }
    @media screen and (min-width: size(1024)) {
      padding-top: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-left: size(50);
      width: auto;
    }
    .banner-artwork {
      display: inline-block;
      vertical-align: bottom;
      @media screen and (min-width: $breakpoint-md) {
        width: 100%;
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(420);
      }
    }
  }
}
