@import "@/assets/styles/global/lib.scss";

.rz-sports-consulting {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .hero-section {
    background: linear-gradient(0deg, #096655, #096655), linear-gradient(0deg, #9C327C, #9C327C), #8E2E72;
    padding: size(50) size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0);
    }
    h1 {
      font-size: size(30);
      line-height: size(42);
      color: var(--regular-text-inv);
      font-weight: 800;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(700);
        margin: 0 auto;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        max-width: 100%;
      }
    }
  }
  .about-rz-container {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(120) size(0) 0;
    }
    h2 {
      font-weight: bold;
      font-size: size(20);
      line-height: size(24);
      color: #2D9CDB;
      padding-bottom: size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
    p {
      font-weight: bold;
      font-size: size(20);
      line-height: size(30);
      color: var(--rz-copy-color);
      max-width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(30);
        line-height: size(42);
      }
    }
  }
  .rz-project-lists {
    .list-container {
      .project-list-wrapper {
        padding: size(50) size(20) 0;
        &:last-child {
          padding-bottom: size(40);
        }
        @media screen and (min-width: $breakpoint-md) {
          padding: size(30) size(20) size(40);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(120) size(0) size(60);
        }
        .content-wrapper {
          max-width: size(600);
          margin: 0 auto;
          padding-top: size(50);
        }
        h2 {
          font-size: size(18);
          line-height: size(30);
          font-weight: bold;
          color: var(--rz-copy-color);
          padding-bottom: size(20);
        }
        p {
          font-size: size(18);
          line-height: size(30);
          color: var(--rz-copy-color);
        }
        .image-wrapper {
          position: relative;
          height: 100%;
        }
        .project-name-container {
          height: size(80);
          padding-bottom: size(20);
          background-size: size(200);
          background-repeat: no-repeat;
          background-position: center;
          @media screen and (min-width: $breakpoint-md) {
            height: size(200);
            background-size: size(400);
            padding-bottom: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            height: size(400);
            background-attachment: fixed;
            background-size: size(800);
          }
        }
      }
    }
  }
  &.sports-consulting {
    .rz-project-lists {
      .list-container {
        .project-list-wrapper {
          &.container-0 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/vordur.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-50);
                width: size(80);
                height: size(80);
                right: 0;
                border-radius: size(10);
                background: url('../../src/assets/rz-website/regluvardar_logo.png') no-repeat center;
                z-index: 2;
                bottom: 0;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(100);
                  height: size(100);
                  right: 10%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(142);
                  height: size(140);
                  right: 3%;
                  border-radius: size(10);
                }
              }
            }
          }
          &.container-1 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/soccer-specific.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/logo-2.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(180);
                  height: size(100);
                  right: 10%;
                  bottom: -10%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(300);
                  height: size(106);
                  right: 0;
                  bottom: -5%;
                  border-radius: size(10);
                }
              }
            }
          }
          &.container-2 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/go-pro-sports.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(80);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/logo-3.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(100);
                  height: size(100);
                  right: 10%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(142);
                  height: size(140);
                  right: 0;
                  border-radius: size(10);
                }
              }
            }
          }
        }
      }
    }
  }
  &.it-consulting {
    .rz-project-lists {
      .list-container {
        .project-list-wrapper {
          &.container-0 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/ZIZERA_logo.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(80);
                height: size(50);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/zizera.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(100);
                  height: size(80);
                  right: 15%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(170);
                  height: size(83);
                  right: 10%;
                  border-radius: size(10);
                }
              }
            }
          }
          &.container-1 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/framsokn_logo.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-20);
                right: 0;
                width: size(80);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/framsokn.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 15%;
                  bottom: 0;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-20);
                  right: 5%;
                  width: size(120);
                  height: size(120);
                  border-radius: size(10);
                }
              }
            }
          }
          &.container-2 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/Distica_logo.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-15);
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/veritas.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 15%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-15);
                  right: 6%;
                  width: size(210);
                  height: size(100);
                  border-radius: size(10);
                }
              }
            }
          }
          &.container-3 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/mideind_logo.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-15);
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/mideind.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(100);
                  height: size(80);
                  right: 12%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-15);
                  right: 0;
                  width: size(195);
                  height: size(110);
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-4 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/grillmarket.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/grillmarket_logo.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(100);
                  height: size(80);
                  right: 10%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-10);
                  right: 5%;
                  width: size(107);
                  height: size(100);
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-5 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/fishmarket.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/fishmarket_logo.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 12%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-10);
                  right: 5%;
                  width: size(167);
                  height: size(120);
                  border-radius: size(20);
                }
              }
            }
          }
        }
      }
    }
  }
  &.ecommerce-consulting {
    .rz-project-lists {
      .list-container {
        .project-list-wrapper {
          &.container-0 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/bilorka.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: -10%;
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/elko_logo.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 15%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  right: 10%;
                  width: size(140);
                  height: size(100);
                  bottom: 0;
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-1 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/bestseller.svg');
              width: 100%;
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/bilanaust_logo.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 15%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  right: 8%;
                  width: size(145);
                  height: size(100);
                  bottom: size(-15);
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-2 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/heimilistaeki.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-10);
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/zo-on_logo.png') no-repeat center;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 15%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-10);
                  right: 8%;
                  width: size(158);
                  height: size(103);
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-3 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/hringdu.svg');
            }
            .image-wrapper {
              .logo-container {
                position: absolute;
                bottom: size(-10);
                right: 0;
                width: size(100);
                height: size(80);
                border-radius: size(10);
                background: url('../../src/assets/rz-website/nomy_logo.png') no-repeat;
                z-index: 2;
                background-size: contain;
                @media screen and (min-width: $breakpoint-md) {
                  right: 12%;
                  bottom: -8%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  bottom: size(-10);
                  right: 5%;
                  width: size(170);
                  height: size(100);
                  border-radius: size(20);
                }
              }
            }
          }
          &.container-4 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/utilif.svg');
            }
          }
          &.container-5 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/a4.svg');
              background-size: 6rem;
              @media screen and (min-width: $breakpoint-md) {
                background-size: 10rem;
              }
              @media screen and (min-width: $breakpoint-lg) {
                background-size: 18rem;
              }
            }
          }
          &.container-6 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/globus.svg');
            }
          }
          &.container-7 {
            .project-name-container {
              background-image: url('../../src/assets/rz-website/blikk.svg');
            }
          }
        }
      }
    }
  }
}
