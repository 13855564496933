@import "@/assets/styles/global/lib.scss";

.rz-home {
  font-family: var(--rz-hero-font);
  .rz-hero-container {
    background: var(--rz-bg-color);
    padding: 0 size(20);
    position: relative;
    @media screen and (min-width: $breakpoint-md) {
      padding: 0 size(30);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
    }
  }
  .btn-style {
    text-decoration: none;
    padding: size(16) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    svg {
      display: inline-block;
      width: size(8);
      height: size(14);
      vertical-align: top;
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
      margin-top: size(3);
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
  .hero-section {
    .content-wrap {
      color: var(--rs-white-color);
      max-width: 100%;
      display: inline-block;
      vertical-align: top;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(300);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(450);
      }
      h1 {
        max-width: size(460);
        font-weight: 800;
        font-size: size(32);
        line-height: size(42);
        padding-bottom: size(20);
        padding-top: size(60);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(60);
          line-height: size(74);
        }
      }
      p {
        font-weight: normal;
        font-size: size(22);
        line-height: size(32);
        padding-bottom: size(20);
      }
      a {
        width: size(110);
      }
    }
    .img-wrap {
      display: inline-block;
      width: 100%;
      height: size(300);
      position: relative;
      @media screen and (min-width: $breakpoint-md) {
        width: 40%;
        height: size(400);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: 52%;
        height: size(700);
      }
      .wheel-fig {
        display: inline-block;
        width: size(200);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-1.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: size(0);
        transition: transform 0.3s linear;
        -webkit-animation:spin 10s linear infinite;
        -moz-animation:spin 10s linear infinite;
        animation:spin 10s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          top: size(70);
          right: size(-100);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          top: size(70);
          right: size(-120);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
      .rectangle-fig {
        display: inline-block;
        width: size(180);
        height: size(180);
        background: url('../../src/assets/rz-website/rz-bg-2.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: size(0);
        transition: transform 0.4s linear;
        -webkit-animation:spin 8s linear infinite;
        -moz-animation:spin 8s linear infinite;
        animation:spin 8s linear infinite;
        @media screen and (min-width: $breakpoint-md) {
          left: size(0);
          bottom: size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(306);
          height: size(280);
          left: size(0);
          bottom: size(100);
        }
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform:rotate(360deg);
        }
      }
    }
  }
  .rz-products-container {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(100);
    }
    .products-inner-wrapper {
      text-align: left;
      color: var(--rz-copy-color);
      div {
        margin: size(15) size(15) size(30);
        @media screen and (min-width: $breakpoint-md) {
          display: inline-block;
          vertical-align: top;
          width: calc((100% / 2) - #{size(40)});
          margin: size(20);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: calc((100% / 3) - #{size(40)});
          vertical-align: top;
          margin: 0;
          &:nth-child(2) {
            margin-left: size(60);
            margin-right: size(60);
          }
        }
        .image-item {
          width: size(34);
          height: size(28);
          margin: 0 0 size(10);
          @media screen and (min-width: $breakpoint-lg) {
            text-align: left;
            margin: 0 0 size(20);
          }
        }
        h4 {
          font-size: size(24);
          line-height: size(34);
          padding-bottom: size(20);
          font-weight: 800;
          text-align: left;
        }
        p {
          font-size: size(18);
          line-height: size(28);
          padding-bottom: size(20);
          text-align: left;
        }
        a {
          text-decoration: none;
          font-weight: bold;
          font-size: size(15);
          line-height: size(21);
          color: #006AFF;
          display: inline-block;
          text-align: left;
          svg {
            display: inline-block;
            width: size(8);
            height: size(8);
            margin-left: size(5);
            fill: none;
            stroke: var(--rz-btn-color);
            transition: transform 0.3s linear;
          }
          &:hover {
            svg {
              transform: translate(#{size(4)});
            }
    }
        }
      }
    }
  }
  .rz-push-config-container {
    padding: size(50) size(20) size(0);
    text-align: center;
    color: var(--rz-copy-color);
    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
      padding-top: size(120)
    }
    h3 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      text-align: center;
      padding-bottom: size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    p {
      font-size: size(18);
      line-height: size(28);
      text-align: center;
      color: var(--rz-copy-color);
      padding-bottom: size(25);
      margin: 0 auto;
      max-width: size(900);
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(40);
      }
    }
  }
  .rz-consulting-list {
    padding: size(50) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(80) size(20) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(120) size(0) 0;
    }
    h5 {
      font-weight: bold;
      font-size: size(18);
      line-height: size(25);
      color: var(--rz-copy-color);
      text-align: left;
      padding-bottom: size(20);
    }
    h4 {
      font-style: normal;
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding-bottom: size(20);
      max-width: size(600);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
      }
    }
    .list-wrapper {
      color: var(--rz-copy-color);
      @media screen and (min-width: $breakpoint-md) {
        display: flex;
        justify-content: space-between;
      }
      p {
        font-weight: normal;
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(20);
      }
      a {
        display: inline-block;
      }
    }
  }
  .rz-newsroom-list {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(24);
    }
    h4 {
      font-weight: 800;
      font-size: size(32);
      line-height: size(42);
      color: var(--rz-copy-color);
      padding: 0 size(20);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(60);
        line-height: size(74);
        padding: 0;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: size(15);
      line-height: size(21);
      color: #006AFF;
      display: inline-block;
      cursor: pointer;
      svg {
        display: inline-block;
        width: size(8);
        height: size(8);
        fill: none;
        stroke: var(--rz-btn-color);
        margin-left: size(5);
        transition: transform 0.3s linear;
      }
      &:hover {
        svg {
          transform: translate(#{size(4)});
        }
      }
      &.more-news {
        color: var(--rz-copy-color);
        padding: 0 size(20);
        margin-top: size(20);
        svg {
          stroke: var(--rz-copy-color);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: 0;
          margin-top: size(40);
        }
      }
    }
    .news-container-wrapper {
      padding: size(30) size(20) 0;
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(40) 0 0;
      }
      h5 {
        font-weight: bold;
        font-size: size(15);
        line-height: size(21);
        padding-bottom: size(10);
        padding-top: size(15);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(20);
        }
      }
      p {
        a {
          font-weight: bold;
          font-size: size(24);
          line-height: size(30);
          color: var(--rz-copy-color);
          margin-bottom: size(20);
          max-width: size(600);
          @media screen and (min-width: $breakpoint-lg) {
            font-size: size(30);
            line-height: size(42);
          }
        }
      }
      .news-right-container {
        @media screen and (min-width: $breakpoint-md) {
          padding-left: size(40);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(80);
        }
      }
    }
  }
}

