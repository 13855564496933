@import "@/assets/styles/global/lib.scss";

.rs-blog-archives {
  --blog-image-max-width: #{size(350)};
  --blog-image-min-width: #{size(290)};
  --blog-image-height: #{size(200)};
  --rs-blog-border-radius: #{size(6)};
  .rz-pre-footer {
    padding: size(40) size(20) 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) 0;
    }
  }

  .blog-archive-list {
    .image-item {
      transition: transform .5s;
      border-radius: var(--rs-blog-border-radius);
      @media screen and (min-width: $breakpoint-md) {
          max-width: var(--blog-image-max-width);
      }
      img {
        width: 100%;
        border-radius: var(--rs-blog-border-radius);
      }
      .image-container {
        border-radius: size(10);
      }
      .show-container {
        text-align: left;
      }
      &.placeholder {
        min-width: size(250);
      }
    }
    .image-wrap {
      position: relative;
      overflow: hidden;
      border-radius: var(--rs-blog-border-radius);
    }
    .list:hover {
      .image-item {
        transform: scale(1.05);
      }
    }
  }
}
