@import "@/assets/styles/global/lib.scss";

.rs-flex-box {
  display: flex;
  flex-wrap: wrap;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
  @media screen and (min-width: $breakpoint-md) {
    &.no-wrap {
      flex-wrap: initial;
    }
  }
}
.max-width-600 {
  max-width: size(600);
  margin: auto;
  text-align: center;
}
.button-wrapper {
  text-align: center;
  padding: size(26) 0 size(50);
  margin: size(40) size(20) 0;
  border-top: size(1) dashed var(--rs-gray-color);
  @media screen and (min-width: $breakpoint-md) {
    margin: size(40) auto 0;
    padding: size(26) 0 size(80);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(26) 0 size(100);
  }
}
.more-blogs {
  max-width: size(1080);
  margin: auto;
  padding: 0 size(20) 0;
  @media screen and (min-width: $breakpoint-lg) {
    padding-bottom: size(70);
  }
  .title {
    color: var(--rs-black-color)
  }
  .list-wrap {
    border: none;
  }
  & > .title {
    padding-bottom: size(20);
    border-bottom: size(1) dashed var(--rs-gray-color);
    font-size: var(--rs-solution-para-font-size);
    line-height: var(--rs-solution-para-line-height);
  }
  .list-wrap {
    & {
      @include headerColorVariant(
        '.title',
        #b02907,
        #ff931e,
        #0751b9,
      );
    }
  }
}

.rs-container {
  max-width: size(1080);
  margin: 0 auto;
  padding: size(50) size(20);

  &.pad-130 {
    padding-top: size(130);
  }
  &.pad-100 {
    padding-top: size(100);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(50) 0;
  }
}
