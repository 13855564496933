@import "@/assets/styles/global/lib.scss";

.home-page{
  --gray-line: #c4c4c4;

  --home-page-pad-top: #{size(20)};
  --home-page-pad-right: #{size(16)};
  --home-page-pad-left: #{size(0)};
  --home-page-pad-secondary-right: #{size(16)};

  --bottom-signup-font-size: #{size(24)};
  --bottom-signup-line-height: #{size(37)};

  @media screen and (min-width: $breakpoint-md) {
    --home-page-pad-top: #{size(56)};
    --home-page-pad-right: #{size(24)};
    --home-page-pad-left: #{size(28)};
    --home-page-pad-secondary-right: #{size(24)};

    --bottom-signup-font-size: #{size(36)};
    --bottom-signup-line-height: #{size(48)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --home-page-pad-top: #{size(54)};
    --home-page-pad-right: #{size(55)};
    --home-page-pad-left: #{size(46)};
    --home-page-pad-secondary-right: #{size(150)};

    --bottom-signup-font-size: #{size(40)};
    --bottom-signup-line-height: #{size(58)};
  }

  padding: 0 0;

  .content, .maxi-content {
    max-width: var(--home-page-content-max-width);
    margin: 0 auto;
  }

  .maxi-content {
    max-width: var(--home-page-header-max-width);
  }

  .rs-hero-title {
    font-size: var(--rs-title-level0-font-size);
    line-height: var(--rs-title-level0-line-height);
    font-weight: 800;
    color: var(--rs-black-color);
    font-family: var(--rz-hero-font);
    max-width: size(520);
    padding-bottom: size(40);
  }
  .rs-section-title {
    font-size: var(--rs-title-level4-font-size);
    line-height: var(--rs-title-level4-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
    font-family: var(--rz-hero-font);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-bottom: size(10);
  }
  .hero-title {
    font-size: var(--rs-title-hero-font-size);
    line-height: var(--rs-title-hero-line-height);
    font-weight: 800;
    color: var(--rs-black-color);
  }
  .rs-flex-box {
    display: flex;
    flex-wrap: wrap;
    &.space-between {
      justify-content: space-between;
    }
    &.align-center {
      align-items: center;
    }
    @media screen and (min-width: $breakpoint-md) {
      &.no-wrap {
        flex-wrap: initial;
      }
    }
  }
  .rs-sm-para {
    font-size: var(--rs-title-level5-font-size);
    line-height: var(--rs-title-level5-line-height);
    color: var(--rs-light-black-color);
    &.blue {
      color: var(--rs-cta-blue-color);
    }
    &.black {
      color: var(--rs-black-color);
    }
    &.bold {
      font-weight: bold;
    }
    &.semi-bold {
      font-weight: 500;
    }
  }

  .rs-container {
    max-width: size(1080);
    margin: 0 auto;
    padding: size(50) size(20) size(10);
    .content-image-wrap {
      &.discount {
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: size(50);
        }
      }
    }
    &.pad-130 {
      padding-top: size(130);
    }
    &.pad-100 {
      padding-top: size(100);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(50) 0;
    }
  }

  .rs-support-block {
    padding-top: size(70);
    .rs-container {
      position: relative;
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(150);
    }
  }

  .rs-resource-home {
    .pay-go-wrap {
      .flex-item {
        margin-right: 0;
      }
    }
  }

  .pay-go-wrap {
    color: var(--rs-black-color);
    &.featured-wrap {
      .flex-item {
        border-bottom: size(1) solid #424242;
        &.inner-box {
          &:first-child, &:nth-child(2) {
            padding-top: size(30);
          }
        }
      }
    }
    .flex-item.endpoint {
      margin-right: 0;
    }
    .auth-section {
      display: none;
    }
    .rs-container {
      padding-bottom: size(50);
      &.pad-10 {
        padding-top: size(20);
      }
    }
    svg {
      width: size(25);
      height: size(25);
    }
    .title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      font-weight: bold;
      text-transform: capitalize;
      display: inline-block;
      color: var(--rs-black-color);
      padding: 0 size(30) size(10) 0;
      cursor: pointer;
      max-width: size(225);
      @media screen and (min-width: #{size(376)}) {
        max-width: size(300);
      }
      @media screen and (min-width: #{size(420)}) {
        max-width: unset;
      }
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
      }
    }
    .endpoint {
      border-bottom: size(1) solid #C4C4C4;
    }
    .flex-item {
      padding-right: size(20);
      &-header, &.inner-box {
        padding-bottom: size(30);
        padding-top: size(30);
      }
      &.inner-box {
        &:first-child, &:nth-child(2) {
          padding-top: size(10);
        }
      }
      &-header {
        cursor: pointer;
        padding-top: size(25);
        padding-bottom: size(25);
        .flex-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .rs-flex-box {
        transition: height .6s cubic-bezier(.645,.045,.355,1), opacity .3s cubic-bezier(.645,.045,.355,1);
        opacity: 1;
        height: 0;
        overflow: hidden;
      }
      &.hide {
        .rs-flex-box {
          opacity: 0;
        }
        .expand {
          &:after {
            transform:rotate(90deg);
          }
          &:before {
            transform: initial;
          }
        }
      }
    }
    .end-point-link {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      color: var(--rs-cta-green-color);
      font-weight: bold;
      display: block;
      &.color-blue {
        color: var(--rs-cta-blue-color);
      }
    }
    .text {
      font-size: var(--rs-para-level0-font-size);
      line-height: var(--rs-para-level0-line-height);
      padding-top: size(10);
      display: inline-block;
    }
    .pricing-info {
      padding-top: size(30);
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(0);
      }
      .label {
        font-size: var(--rs-sm-label-font-size);
        line-height: var(--rs-sm-label-line-height);
        color: var(--rs-gray-color);
      }
    }
    .make-bold {
      font-size: var(--rs-title-level3-font-size);
      line-height: var(--rs-title-level3-line-height);
      font-weight: bold;
    }
    .flex-item {
        border-bottom: size(1) solid var(--rs-border-gray-color);
        padding-right: size(0);
        &:nth-child(1) {
          .flex-box-inner {
            border-top: none;
          }
        }
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-right: size(60);
          &:nth-child(2) {
            .flex-box-inner {
              border-top: none;
            }
          }
          &:nth-child(even) {
            margin-right: size(0);
          }
          &:nth-last-of-type(1), &:nth-last-of-type(2) {
              border-bottom: none;
              @media screen and (min-width: $breakpoint-md) {
                border-bottom: unset;
              }
            }
        }
        &.endpoint {
          border-bottom: size(1) solid #C4C4C4;
        }
        &.show-border {
          border-bottom: size(1) solid var(--rs-border-gray-color);
          padding-bottom: size(30);
        }
      }
      .flex-box-inner {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-direction: column;
          @media screen and (min-width: $breakpoint-md) {
            flex-direction: row;
          }
          @media screen and (min-width: $breakpoint-lg) {
            // padding-top: size(30);
            &:nth-child(2) {
              border-top: none;
            }
            padding-top: size(0);
            width: size(510);
          }
          // .flex-item-rs {
            &:nth-child(1) {
              .block {
                padding-top: 0;
              }
            }
            .block {
              padding-bottom: size(40);
              padding-top: size(40);
              display: flex;
              border-bottom: size(1) solid var(--rs-border-gray-color);
              &-left {
                @media screen and (min-width: $breakpoint-md) {
                  padding-right: size(50);
                  flex: 0 0 74%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  flex: initial;
                }
              }
              &-right {
                padding-right: 0;
                @media screen and (min-width: $breakpoint-md) {
                  min-width: unset;
                  margin-left: auto;
                  align-self: flex-end;
                }
                @media screen and (min-width: $breakpoint-md) {
                  align-self: flex-start;
                  margin-top: size(35);
                }
                .tooltip {
                  font-size: size(15);
                  line-height: size(21);
                }
                .pricing-info {
                  .label , .make-bold, .per-month {
                    min-width: size(140);
                    text-align: left;
                  }
                }
              }
            }
          // }
        }
    .featured-apis-wrap {
      background: var(--rs-black-color);
      color: var(--rs-white-color);
      .title {
        color: var(--rs-white-color);
      }
    }
    .expand {
      cursor: pointer;
      position:relative;
      padding: 0 size(10);
      width: size(16);
      height: size(16);
      background-color: transparent;
      border: 0;
      &:before, &:after {
        content: "";
        position: absolute;
        background-color: var(--rs-black-color);
        transition: transform 300ms cubic-bezier(.694,0,.335,1);
        width: size(2);
        height: size(16);
        top: 0;
      }
      &:after {
        transform: rotate(270deg);
      }
      &:before {
        transform: rotate(90deg);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(50);
      &.featured-wrap {
        padding-bottom: size(0);
      }
      .flex-item {
        flex: 0 0 100%;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .flex-item {
        flex: 0 0 30%;
      }
    }
  }
  .strike-price {
    font-size: var(--rs-title-level5-font-size);
    line-height: var(--rs-title-level5-line-height);
    text-decoration: line-through;
    display: block;
    padding-bottom: size(4);
    font-weight: 500;
    &.inline {
      display: inline-block;
    }
  }

  .bottom-signup-block {
    @media screen and (min-width: $breakpoint-md) {
      padding-top: #{size(10)};
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-bottom: #{size(35)};
    }
    h3 {
      font-family: var(--hero-font);
      font-size: var(--bottom-signup-font-size);
      line-height: var(--bottom-signup-line-height);
      text-align: center;
      padding-left: size(32);
      padding-right: size(32);
      max-width: #{size(293)};
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(750);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: unset;
        margin: unset;
      }
    }
    .hero-links {
      display: flex;
      justify-content: center;
      padding: size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
          padding: size(60) 0 0 0;
      }
      .btn {
        margin-right: size(11);
        border-width: 2px;
        border-color: var(--ca-btn-bg);
        text-transform: none;
        font-family: var(--regular-font);
        font-size: var(--banner-btn-font-size);
        font-weight: 600;
        line-height: var(--banner-btn-line-height);
        padding: size(10.5) size(16);
        letter-spacing: 0;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(15) size(30);
        }
      }
      li {
        &:first-child .btn, &:not(:first-child) .btn:hover {
          background-color: var(--ca-btn-bg);
          color: var(--regular-text-inv);
        }
      }
    }
  }
  .content.placeholder {
    padding: size(30) size(10);
    max-width: var(--home-page-header-max-width);
  }
  .flex-col {
    min-height: size(700);
  }

  .blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    color: var(--rs-black-color);
    .list-wrap {
      flex: 0 0 100%;
      padding-top: size(30);
      padding-bottom: size(30);
      border-bottom: size(1) dashed var(--rs-gray-color);
      @media screen and (min-width: $breakpoint-md) {
        flex-basis: 33%;
        .list {
          width: 90%;
        }
        &:nth-child(3n) {
          .list {
            float: right;
          }
        }
        &:nth-child(3n + 2) {
          .list {
            margin: auto;
          }
        }
      }
      img {
        border-radius: var(--rs-blog-border-radius);
        margin-bottom: size(18);
      }
      .image-wrap {
        margin-bottom: size(12);
      }
      .title {
        color: var(--rs-orange-color);
        padding-bottom: size(5);
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        font-weight: bold;
      }
      .desc {
        padding-bottom: size(18);
        color: var(--regular-text);
        font-size: var(--rs-title-level4-font-size);
        line-height: var(--rs-title-level4-line-height);
      }
      .date {
        color: var(--rs-gray-color);
        font-size: var(--rs-sm-label-font-size);
        line-height: var(--rs-sm-label-line-height);
      }
      &.on-tab {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
      }
      a:hover {
        .desc {
          color: var(--rs-cta-blue-color);
        }
      }
      & {
        @include headerColorVariant(
          '.title',
          #b02907,
          #ff931e,
          #0751b9,
          #ba298a,
          #62cd9f,
          #856900
        );
      }
    }
    .image-item {
      transition: transform 0.5s;
      img {
        width: 100%;
        border-radius: var(--rs-blog-border-radius);
        margin: auto;
      }
      .show-container {
        text-align: left;
      }
    }
    .list-wrap:hover {
      .image-item {
        transform: scale(1.03);
      }
    }
    .list-wrap {
      .image-item {
        transform: scale(1);
      }
    }
    .image-wrap {
      position: relative;
      overflow: hidden;
      border-radius: var(--rs-blog-border-radius);
      height: size(210);
      img {
        object-fit: cover;
      }
    }
  }
}
