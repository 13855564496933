@import "@/assets/styles/global/lib.scss";

.console-btn {
  background: var(--rz-btn-color);
  &.btn-style {
    text-decoration: none;
    padding: size(10) size(20);
    background: var(--rz-btn-color);
    color: var(--rs-white-color);
    display: inline-block;
    border-radius: size(5);
    border: size(1) solid var(--rz-btn-color);
    font-weight: bold;
    font-size: size(15);
    line-height: size(21);
    cursor: pointer;
    width: size(100);
    text-transform: capitalize;
    margin-bottom: size(14);
    svg {
      display: inline-block;
      vertical-align: top;
      padding-top: size(6);
      width: size(8);
      height: size(10);
      fill: transparent;
      margin-left: size(5);
      transition: transform 0.3s linear;
    }
    &:hover {
      svg {
        transform: translate(#{size(4)});
      }
    }
  }
}
