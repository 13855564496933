@import "@/assets/styles/global/lib.scss";

.container {
  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }
  &:hover {
    .arrow-div {
      opacity: 1;
    }
  }
}
.arrow-div {
  text-align: center;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 2;
  top: 45%;
  left: 0;
  right: 0;
  transform: translateY(-45%);
  transition: opacity 0.5s linear;
  @media screen and (min-width: $breakpoint-lg) {
    display: block;
    opacity: 0;
  }
  span {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    &.left-arrow {
      left: size(-10);
    }
    &.right-arrow {
      right: size(-10);
    }
    svg {
      width: size(40);
      height: size(40);
      cursor: pointer;
      fill: none;
    }
  }
}
