@import "@/assets/styles/global/lib.scss";

.rz-contact-us {
  font-family: var(--rz-hero-font);
  color: var(--rz-copy-color);
  .address-container {
    background: #030303;
    padding: size(40) size(20);
    @media screen and (min-width: $breakpoint-lg) {
     padding: size(60) size(0);
    }
    .common-wrapper {
      text-align: center;
      @media screen and (min-width: $breakpoint-lg) {
        text-align: left;
      }
      div {
        display: inline-block;
        vertical-align: top;
        max-width: size(300);
        padding-top: size(20);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: 0;
          &:nth-child(2n) {
            padding: 0 size(90);
          }
        }
      }
      h4 {
        font-size: size(15);
        line-height: size(21);
        text-transform: uppercase;
        color: #03B4D7;
        padding-bottom: size(20);
      }
      p {
        font-size: size(22);
        line-height: size(32);
        color: var(--regular-text-inv);
        padding-bottom: size(20);
      }
      a {
        text-decoration: none;
        font-size: size(15);
        line-height: size(21);
        color: var(--regular-text-inv);
        margin-left: size(2);
        cursor: pointer;
        svg {
          display: inline-block;
          width: size(8);
          height: size(8);
          transition: margin 0.3s linear;
        }
        &:hover {
          svg {
            margin-left: size(8);
          }
        }
      }
    }
  }
}
