@import "@/assets/styles/global/lib.scss";

.rs-blog-list {
  --rs-blog-border-radius: #{size(6)};
  .blog-list-two-col {
    .flex-child.two .image-item {
      img {
        height: auto;
        max-height: size(600);
        border-radius: var(--rs-blog-border-radius);
      }
    }
  }
  .rz-pre-footer {
    padding: size(40) size(20) 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60) size(0) 0;
    }
  }
}
