// Note!!
// This is imported on every vue and scss files. 
// So keep only variables here.

$base-size: 16;

//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@use "sass:math";

@function size($target, $context: $base-size) {
  @return math.div($target , $context) * 1rem;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin headerColorVariant($name, $colorArr...) {
  @for $i from 0 to length($colorArr) {
    &:nth-of-type(#{length($colorArr)}n + #{$i }) {
      #{$name} {
        color: #{nth($colorArr, $i + 1)};
      }
    }
  }
}

@mixin jump-up-animation {
  animation: jump-up 350ms ease-out;
}

@mixin titile_gradient($color1, $color2) {
  position: relative;
  background-image: linear-gradient(45deg, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
  animation: hue 10s infinite ease-out;
  @keyframes hue {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(-360deg);
    }
  }
}


// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
// Media Query Break Points
$breakpoint-sm: size(320);
$breakpoint-md: size(768);
$breakpoint-lg: size(1194);
