@import "@/assets/styles/global/lib.scss";

.wrapper {
  max-width: size(320);
  margin: 0 auto;
  @media screen and (min-width: $breakpoint-md) {
    max-width: size(700);
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(910);
  }
}
.rz-hero-container {
  .hero-section {
    perspective: 100vw;
    transition: transform 1s ease;
    -webkit-transition: transform 1s ease;
    -moz-transition: transform 1s ease;
    -o-transition: transform 1s ease;
  }
  .wrapper-1, .wrapper-3 {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform-style: flat;
    cursor: pointer;
    transition: transform 1.2s ease;
    -webkit-transition: transform 1.2s ease;
    -moz-transition: transform 1.2s ease;
    -o-transition: transform 1.2s ease;
  }
  .wrapper-2 {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform-style: flat;
    cursor: pointer;
    &.image-container-0 {
      position: absolute;
      top: size(96);
      z-index: 2;
      width: size(300);
      height: size(400);
      z-index: 10;
      left: 0;
      @media screen and (min-width: $breakpoint-md) {
        width: size(550);
        height: size(600);
        left: size(0);
        top: size(40);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(701);
        height: size(700);
        top: size(80);
      }
      .image-item {
        transition: transform 0.5s linear;
        -webkit-transition: transform 0.5s linear;
        -moz-transition: transform 0.5s linear;
        -o-transition: transform 0.5s linear;
        cursor: pointer;
        img {
          display: inline-block;
          width: size(300);
          height: size(400);
          object-fit: contain;
          @media screen and (min-width: $breakpoint-md) {
            width: size(550);
            height: size(600);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(701);
            height: size(700);
          }
        }
      }
    }
    &.image-container-1 {
      position: absolute;
      top: size(245);
      z-index: 2;
      right: size(50);
      width: size(40);
      height: size(40);
      z-index: 10;
      @media screen and (min-width: $breakpoint-md) {
        right: size(178);
        width: size(75);
        height: size(75);
        top: size(250);
      }
      @media screen and (min-width: $breakpoint-lg) {
        right: size(200);
        top: size(350);
        width: size(100);
        height: size(100);
      }
      .image-item {
        img {
          display: inline-block;
          width: size(40);
          height: size(40);
          object-fit: contain;
          @media screen and (min-width: $breakpoint-md) {
            width: size(90);
            height: size(90);
          }
        }
      }
    }
  }
  .wrapper-1 {
    position: absolute;
    z-index: 5;
    top: size(40);
    @media screen and (min-width: $breakpoint-lg) {
      top: size(70);
    }
    .creating-text-div {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(20);
      }
      img {
        display: inline-block;
        width: size(232);
        height: size(80);
        @media screen and (min-width: $breakpoint-md) {
          width: size(350);
          height: size(120);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(451);
          height: size(140);
        }
      }
    }
    .intelligence-text-div {
      padding-left: size(10);
      padding-bottom: size(120);
      @media screen and (min-width: $breakpoint-md) {
        padding-left: size(250);
        padding-bottom: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-left: size(300);
        padding-bottom: size(360);
      }
      img {
        display: inline-block;
        width: size(312);
        height: size(80);
        @media screen and (min-width: $breakpoint-md) {
          width: size(450);
          height: size(120);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(607);
          height: size(140);
        }
      }
    }
  }
  .hero-section {
    position: relative;
    height: size(430);
    @media screen and (min-width: $breakpoint-md) {
      height: size(630);
    }
    @media screen and (min-width: $breakpoint-lg) {
      height: size(750);
    }
  }
  .wrapper-3 {
    position: absolute;
    z-index: 15;
    bottom: 0;
    left: size(30);
    bottom: size(40);
    @media screen and (min-width: $breakpoint-md) {
      bottom: size(120);
    }
    img {
      display: inline-block;
      width: size(270);
      height: size(80);
      @media screen and (min-width: $breakpoint-md) {
        width: size(450);
        height: size(120);
      }
      @media screen and (min-width: $breakpoint-lg) {
        width: size(550);
        height: size(140);
      }
    }
  }
}
