@import "@/assets/styles/global/lib.scss";

.home-footer-block {
  &.rz-footer {
    .footer {
      &.common-wrapper {
        max-width: size(1080);
        margin: 0 auto;
      }
      .all-links {
        .inner-block {
          .company {
            .image-wrap {
              img {
                display: inline-block;
                width: size(50);
                height: sie(52);
                @media screen and (min-width: $breakpoint-md) {
                  width: size(60);
                  height: sie(62);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(72);
                  height: sie(80);
                }
              }
            }
          }
          .links {
            h3 {
              font-size: size(12);
              line-height: size(17);
              color: #777777;
              padding-bottom: size(10);
              margin-bottom: 0;
            }
            &.button-wrapper {
              display: block;
              width: 100%;
              @media screen and (min-width: $breakpoint-md) {
                display: flex;
                flex-direction: column;
                width: auto;
              }
            }
            .support-btn-wrapper {
              text-decoration: none;
              color: var(--rs-white-color);
              display: inline-block;
              padding: size(10);
              border-radius: size(5);
              background: var(--regular-text-inv);
              border: size(1) solid var(--rz-copy-color);
              font-weight: bold;
              font-size: size(15);
              line-height: size(21);
              cursor: pointer;
              text-transform: capitalize;
              margin-bottom: size(14);
              @media screen and (min-width: $breakpoint-md) {
                padding: size(10) size(7);
              }
              svg {
                display: inline-block;
                width: size(4);
                height: size(8);
                fill: transparent;
              }
              a {
                &:hover {
                  color: var(--rz-copy-color);
                }
              }
            }
            .links-section-wrapper {
              .text-button {
                .link-text {
                  font-size: size(15);
                  line-height: size(21);
                  color: var(--rz-copy-color);
                  font-weight: normal;
                }
              }
              &.Follow {
                .text-button {
                  .link-text {
                    padding-left: size(30);
                    position: relative;
                  }
                }
              }
            }
          }
          .text-button {
            .link {
              padding-bottom: size(14);
              padding-top: 0;
            }
            .link-text {
              &::before {
                display: inline-block;
                width: size(21);
                height: size(21);
                vertical-align: middle;
                position: absolute;
                left: 0
              }
            }
            &.Instagram {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/instagram.svg') no-repeat;
                }
              }
            }
            &.Twitter {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/twitter.svg') no-repeat;
                }
              }
            }
            &.Facebook {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/facebook.svg') no-repeat;
                }
              }
            }
            &.LinkedIn {
              .link-text {
                &::before {
                  content: '';
                  background: url('../assets/rz-website/linkedin.svg') no-repeat;
                }
              }
            }
          }
        }
      }
    }
  }
}
