@import "@/assets/styles/global/lib.scss";

.master-sub-menu {
  margin: size(8) size(30) size(16) size(30);
  border-left: 1px solid var(--divider);
  font-size: size(14);
  &.rz-menu {
    border-left: none;
  }
  li {
    display: block;
    a {
      display: block;
      padding: size(12);
      font-size: size(12);
      color: var(--regular-text-inv);
      text-transform: uppercase;
      &.link-box {
        text-transform: initial;
      }
      &:hover {
        @include jump-up-animation;
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: size(8) size(8) size(8) size(20);
    border-left: none;
    li {
      border-bottom: 1px solid var(--divider);

      &:last-child {
        border-bottom: none;
      }

      a {
        padding: size(12) 0;
      }
    }
  }
}

.docs-wrap {
  .master-sub-menu {
    li {
      .desc-area {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .master-menu {
    &.overlay-menu {
      .with-sub-menu {
        &:hover {
          .sub-menu-items {
            background: var(--master-bg-lite);
            .master-sub-menu {
              li {
                border-color: var(--secondary-text);
                a {
                  color: var(--secondary-text);
                }
              }
            }
          }
        }
      }
    }
  }
}
